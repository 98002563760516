body{
    background:black !important
  }
  
  .map {
    height: 400px;
    width: 100%;
  }
  p{
    font-size: large;
    text-align: justify;
  }
  .footerTitle{
    font-size: 16px
  }
  .iconDetails{
    text-transform: uppercase;
  }
  
  .UpperTransform{
  }
  .fadeOut{
       opacity:0;
       width:0;
       height:0;
       transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  
  }
  .fadeIn{
    color:'black';
  }
  
  li>a:focus{
    background-color: orange!important;
  }
  .zoom {
    transition: transform .2s;
    margin: 0 auto;
  }
  .zoom:hover {
  
     opacity: 1;
  }
  .dropdown-menu>li>a:hover{
    background-color: orange!important;
  }